import { FC, useCallback } from 'react';
import styled from 'styled-components';

interface ProjectCardProps {
  name: string;
  path: string;
  image: string;
}

export const ProjectCard: FC<ProjectCardProps> = ({ name, path, image }) => {
  const navigate = useCallback((url: string) => {
      window.open(url);
    }, [])

    return (
      <StyledCard>
        <StyledTitle>{name}</StyledTitle>
        <StyledImage alt={name} src={image}/>
        <button onClick={ () => navigate(path)}>Preview</button>
      </StyledCard>
    );
}

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  background-color: #00000030;
`;

const StyledTitle = styled.h3`
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  background-image: var(--yellow-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledImage = styled.img`
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
`;
