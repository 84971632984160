import { SessionProvider } from '../providers/session';
import { Module } from '../common/Module';
import { ProjectsList } from '../modules/ProjectsList';
import { M3U8 } from '../modules/m3u8';
import React from 'react';
import styled from 'styled-components';
import logo from '../logo.svg';

export const HomePage = () => {
  return <SessionProvider>
    <StyledApp>
      <h1>Mykola Nikoliuk</h1>
      <StyledModules>
        <Module name="Projects">
          <ProjectsList />
        </Module>
        <Module name="Video Stream Dowloader (M3U8)">
          <M3U8 />
        </Module>
      </StyledModules>
      <StyledLogo />
    </StyledApp>
  </SessionProvider>
}

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: calc(100dvh - 32px);
  overflow: auto;
  
  h1 {
    margin: 0;
    color: white;
    text-align: center;
  }
`;

const StyledModules = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

const StyledLogo = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 64px;
  height: 64px;
  background: url("${logo}") no-repeat right bottom;
  background-size: 100% auto;
`;
