import React from 'react';
import styled from 'styled-components';
import { ProjectCard } from './ProjectCard';
import knightGameAnimation from './images/knight-game-animation.gif';
import pathFinderAnimation from './images/path-finder-animation.gif';

export const ProjectsList = () => {
  return (
    <StyledWrapper>
      <ProjectCard name="Knight Game" path="/projects/knight-game/" image={knightGameAnimation} />
      <ProjectCard name="Path Finder" path="/projects/path-finder" image={pathFinderAnimation} />
      {/*<button onClick={ () => navigate("/projects/path-finder")}>Path Finder</button>*/}
      {/*<button onClick={ () => navigate("/projects/knight-game")}>Knight Game</button>*/}
      {/*<button disabled onClick={ () => navigate("/projects/engine")}>3D Terrain Editor</button>*/}
      {/*<button disabled onClick={ () => navigate("/examples/Animator")}>Animator (Library)</button>*/}
      {/*<button disabled onClick={ () => navigate("/ml")}>Symbol Neural Network</button>*/}
    </StyledWrapper>
  );
}


const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  z-index: 1;
`;
